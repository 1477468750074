(function() {
    if (document.querySelector('.pnp__overlay-opener')) {
        import('./overlay.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('.pnp__image-marquee-container')) {
        import('./marquee.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('#pnp__location-map')) {
        import('./mapbox.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('.pnp__contact-form')) {
        import('./contact.js').then(result => {
            result.default();
        });
    }


    if (document.querySelector('.pnp__blog-collections')) {
        import('./collection-carousel.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('.filterable')) {
        import('./filterable.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('*[data-video-url]')) {
        import('./video.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('.pnp__link-collection.format-carousel')) {
        import('./link-carousel.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('#pnp__calendar')) {
        import('./calendar.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('.pnp__mailchimp-form')) {
        import('./mailchimp').then(r => {
            r.default();
        })
    }

    if (document.querySelector('img.lazy')) {
        import('./lazy-load').then(result => {
            result.default();
        });
    }

    if (document.querySelector('#pnp__mailing-list-extended-signup')) {
        import('./mailing.js').then(result => {
            result.default();
        });
    }

    if (document.querySelector('#pb__blog--load-more')) {
        import('./blog-load-more.js').then(result => {
            result.default()
        });
    }

    if (document.querySelector('.filterable-select')) {
        import('./startupsfilter.js').then(result => {
            result.default()
        });
    }

    document.querySelector('.pnp__menu-toggle').addEventListener('click', e => {
        e.currentTarget.classList.toggle('open');
        document.querySelector('.pnp__main-nav').classList.toggle('open');
        document.querySelector('#pnp__header-navigation').classList.toggle('open');
    });

    import('./reveal.js').then(e => {
        e.default();
    });

    let links = [].slice.call(document.querySelectorAll('a'));

    links.forEach(element => {
        if (element.href.indexOf(window.location.hostname) == -1) {
            element.setAttribute('target', '_blank');
        }
    });

    let blogLinks = [].slice.call(document.querySelectorAll('.pnp__blog-content-container a'));
    let postContainer = document.querySelector('.pnp__blog-page-body.follow-links');

    blogLinks.forEach(element => {
        if (
            element.href.indexOf(window.location.hostname) == -1 &&
            postContainer === null
        ) {
            element.setAttribute('rel', 'nofollow');
        }
    });
})();
